import type { ComponentType } from "react"

export function TrackSignupButtonClick(Component): ComponentType {
    return (props) => {
        const onClick = (event) => {
            if (window.umami) {
                window.umami.track("Click Signup Header") // Event name
            }
            if (props.onClick) {
                props.onClick(event) // Preserve existing click handlers
            }
        }
        return <Component {...props} onClick={onClick} />
    }
}
